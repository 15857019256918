import { SimpleGrid } from '@chakra-ui/react';
import { useDisclosure } from '@pankod/refine-chakra-ui';
import { IconCalculator, IconCreditCard } from '@tabler/icons';
import { CardSection, DashboardActionButton, useStartNewOrder } from 'ui-core';
import { CalculatorModal, PaymentFormModal } from 'ui-rto';

import { FileIcon } from '../../icons';

export const ActionsCard = () => {
    const startNewOrder = useStartNewOrder({ navigateOnSucess: true });

    const {
        isOpen: calculatorIsOpen,
        onOpen: openCalculator,
        onClose: calculatorOnClose,
    } = useDisclosure();

    const {
        isOpen: paymentsIsOpen,
        onOpen: openPayments,
        onClose: paymentsOnClose,
    } = useDisclosure();

    const onClickCreateContract = () => {
        startNewOrder.mutate();
    };

    const onClickCalc = () => {
        openCalculator();
    };

    const onClickPayment = () => {
        openPayments();
    };

    return (
        <>
            <CardSection title="Actions">
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                    <DashboardActionButton
                        label="Create Contract"
                        icon={<FileIcon w="32px" h="32px" />}
                        onClick={onClickCreateContract}
                        isLoading={startNewOrder.isLoading}
                    />
                    <DashboardActionButton
                        label="Calculator"
                        icon={<IconCalculator size="32px" />}
                        onClick={onClickCalc}
                    />
                    <DashboardActionButton
                        label="Make a payment"
                        icon={<IconCreditCard size="32px" />}
                        onClick={onClickPayment}
                    />
                </SimpleGrid>
            </CardSection>
            <CalculatorModal isOpen={calculatorIsOpen} onClose={calculatorOnClose} />
            <PaymentFormModal isOpen={paymentsIsOpen} onClose={paymentsOnClose} />
        </>
    );
};

export default ActionsCard;