import { extendTheme } from '@chakra-ui/react';
import { getThemeExtension } from './theme-extension';

export function getBaseTheme() {
    return extendTheme(
        {
            config: {
                initialColorMode: 'light',
            },
            colors: {
                sider: {
                    background: '#1d2327',
                    collapseButton: '#1d2327',
                },
            },
            styles: {
                global: {
                    '.editor-paragraph': {
                        marginBottom: '1em',
                    },
                    '.editor-text-bold': {
                        fontWeight: 'bold',
                    },
                    '.editor-text-italic': {
                        fontStyle: 'italic',
                    },
                    '.editor-text-underline': {
                        textDecoration: 'underline',
                    },
                }
            },
        },
        getThemeExtension()
    );
}
