export function formatPhone(phone: string) {
    if (phone && phone.length == 10) {
        return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`;
    }
    return phone;
}

export function formatPrice(amount: number | string) {
    return Number(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

const englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });

export function formatNumberOrdinal(number: number | string) {
    const category = englishOrdinalRules.select(Number(number));

    switch (category) {
        case 'one': {
            return `${number}st`;
        }
        case 'two': {
            return `${number}nd`;
        }
        case 'few': {
            return `${number}rd`;
        }
        default: {
            return `${number}th`;
        }
    }
}

const BrandingConfig = {
    JMAG: {
        name: "JMAG",
        titleImage: "/jmag-logo-white.png",
        titleImageCollapsed: "/jmag-logo-collapsed.png",
    },
    CAL: {
        name: "CAL",
        titleImage: "/rtohub-darkback-logo.png",
        titleImageCollapsed: "/cal_small.png",
    }

}

export const Branding = BrandingConfig.CAL;
