import { useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import {
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Heading,
    Input,
    Select,
    HStack,
    VStack,
} from '@chakra-ui/react';

import { ActionBar, RhfPhoneInput, useApiSdk, US_STATES } from 'ui-core';

import {
    useRtoOrderFormData,
    useInvalidateRtoOrderData,
} from '../../../hooks/use-rto-order-form-data';

type AddressFormProps = {
    title?: string;
    type: 'billing' | 'shipping';
    form: UseFormReturn<any>;
};

const AddressForm = (props: AddressFormProps) => {
    const register = props.form.register;
    const addressType = props.type;
    const errors = props.form.formState.errors;

    return (
        <VStack alignItems="start" width="100%" spacing={4}>
            {props.title && (
                <Box>
                    <Heading size="md">{props.title}</Heading>
                </Box>
            )}
            <FormControl isInvalid={!!errors[addressType + '_street1']}>
                <FormLabel>Street address</FormLabel>
                <Input
                    as={Input}
                    {...register(addressType + '_street1', { required: 'Required.' })}
                />
                <FormErrorMessage>{errors[addressType + '_street1']?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors[addressType + '_street2']}>
                <FormLabel>Apartment, suite, etc.</FormLabel>
                <Input as={Input} {...register(addressType + '_street2')} />
                <FormErrorMessage>{errors[addressType + '_street2']?.message}</FormErrorMessage>
            </FormControl>
            <HStack width="100%" alignItems="top">
                <FormControl flex="1" isInvalid={!!errors[addressType + '_city']}>
                    <FormLabel>City</FormLabel>
                    <Input {...register(addressType + '_city', { required: 'Required.' })} />
                    <FormErrorMessage>{errors[addressType + '_city']?.message}</FormErrorMessage>
                </FormControl>
                <FormControl flex="1" isInvalid={!!errors[addressType + '_state']}>
                    <FormLabel>State</FormLabel>
                    <Select {...register(addressType + '_state', { required: 'Required.' })}>
                        <option value=""></option>
                        {Object.entries(US_STATES).map(([code, name]) => (
                            <option key={code} value={code}>
                                {name}
                            </option>
                        ))}
                    </Select>
                    <FormErrorMessage>{errors[addressType + '_state']?.message}</FormErrorMessage>
                </FormControl>
                <FormControl width="8rem" isInvalid={!!errors[addressType + '_zip']}>
                    <FormLabel>Zip</FormLabel>
                    <Input {...register(addressType + '_zip', { required: 'Required.' })} />
                    <FormErrorMessage>{errors[addressType + '_zip']?.message}</FormErrorMessage>
                </FormControl>
            </HStack>
        </VStack>
    );
};

export const StepCustomer = () => {
    const sdk = useApiSdk();

    const pageQuery = useRtoOrderFormData();
    const invalidate = useInvalidateRtoOrderData();

    const initialState: Record<string, any> = useMemo(() => {
        const order = pageQuery.data?.order;
        let formData: Record<string, any> = {
            first_name: order?.customer?.firstName || '',
            last_name: order?.customer?.lastName || '',
            email: order?.customer?.emailAddress || '',
            phone: order?.customer?.phoneNumber || '',
            shipping_street1: order?.shippingAddress?.streetLine1 || '',
            shipping_street2: order?.shippingAddress?.streetLine2 || '',
            shipping_city: order?.shippingAddress?.city || '',
            shipping_state: order?.shippingAddress?.province || '',
            shipping_zip: order?.shippingAddress?.postalCode || '',
            billing_street1: order?.billingAddress?.streetLine1 || '',
            billing_street2: order?.billingAddress?.streetLine2 || '',
            billing_city: order?.billingAddress?.city || '',
            billing_state: order?.billingAddress?.province || '',
            billing_zip: order?.billingAddress?.postalCode || '',
            billing_same_as: true,
        };

        return formData;
    }, [pageQuery.data]);

    const form = useForm({ defaultValues: initialState });
    const register = form.register;
    const errors = form.formState.errors;

    const billingSameAsShipping = form.watch('billing_same_as');

    const onSubmit = async (formData: any) => {
        const order = pageQuery.data?.order;

        if (order) {
            const shippingAddress = {
                fullName: `${formData.first_name} ${formData.last_name}`,
                streetLine1: formData.shipping_street1,
                streetLine2: formData.shipping_street2,
                city: formData.shipping_city,
                province: formData.shipping_state,
                postalCode: formData.shipping_zip,
                countryCode: 'US',
            };

            let billingAddress = shippingAddress;

            if (!formData.billing_same_as) {
                billingAddress = {
                    fullName: `${formData.first_name} ${formData.last_name}`,
                    streetLine1: formData.billing_street1,
                    streetLine2: formData.billing_street2,
                    city: formData.billing_city,
                    province: formData.billing_state,
                    postalCode: formData.billing_zip,
                    countryCode: 'US',
                };
            }

            const result = await sdk.SetRtoCustomer({
                input: {
                    orderId: order.id,
                    customer: {
                        firstName: formData.first_name,
                        lastName: formData.last_name,
                        emailAddress: formData.email,
                        phoneNumber: formData.phone,
                    },
                    billingAddress,
                    shippingAddress,
                },
            });

            invalidate(result.setRtoCustomer?.id);
        }
    };

    const handleClickContinue = async () => {
        return form.handleSubmit(onSubmit, () => {
            throw new Error('Invalid form.');
        })();
    };

    return (
        <form>
            <VStack width="100%" alignItems="start" spacing={10} color="gray.700">
                <VStack alignItems="start" width="100%" spacing={4}>
                    <Box>
                        <Heading size="md">Contact information</Heading>
                    </Box>
                    <HStack width="100%" alignItems="top">
                        <FormControl flex="1" isInvalid={!!errors.first_name}>
                            <FormLabel>First name</FormLabel>
                            <Input {...register('first_name', { required: 'Required.' })} />
                            <FormErrorMessage>{errors.first_name?.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl flex="1" isInvalid={!!errors.last_name}>
                            <FormLabel>Last name</FormLabel>
                            <Input {...register('last_name', { required: 'Required.' })} />
                            <FormErrorMessage>{errors.last_name?.message}</FormErrorMessage>
                        </FormControl>
                    </HStack>
                    <FormControl isInvalid={!!errors.email}>
                        <FormLabel>Email address</FormLabel>
                        <Input {...register('email', { required: 'Required.' })} />
                        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.phone}>
                        <FormLabel>Phone</FormLabel>
                        <RhfPhoneInput
                            name="phone"
                            control={form.control}
                            rules={{ required: 'Required.' }}
                        />
                        <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
                    </FormControl>
                </VStack>
                <AddressForm type="shipping" title="Delivery address" form={form} />
                <Box>
                    <Checkbox {...register('billing_same_as')}>
                        Use same address for billing
                    </Checkbox>
                </Box>
                {!billingSameAsShipping && (
                    <AddressForm type="billing" title="Billing address" form={form} />
                )}
                <ActionBar submitLabel="Continue" onSubmit={handleClickContinue} />
            </VStack>
        </form>
    );
};

export default StepCustomer;
