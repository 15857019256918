import { useId, useState } from 'react';

import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    Icon,
    IconButton,
    Input,
    Link,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Table,
    Tbody,
    Thead,
    Td,
    Th,
    Tr,
    Stack,
    useMultiStyleConfig,
} from '@chakra-ui/react';
import { IconDownload, IconCheck, IconChevronDown, IconUpload } from '@tabler/icons';
import { useInvalidate, useNotification } from '@pankod/refine-core';

import { useApiSdk, useAppModeData } from 'ui-core';

export type DocumentsBoxProps = {
    order: any;
};

type SignerInfoProps = {
    signer: any;
    appMode: ReturnType<typeof useAppModeData>['mode'];
};

declare const API_BASE_URL: string;

const SignerInfo = (props: SignerInfoProps) => {
    const signer = props.signer;

    return (
        <Box>
            <Menu>
                <MenuButton
                    as={Button}
                    rightIcon={<IconChevronDown size="16px" />}
                    leftIcon={
                        signer.status == 'fulfilled' && (
                            <Icon as={IconCheck} color="green.500" verticalAlign="middle" />
                        )
                    }
                    variant="outline"
                    colorScheme="gray"
                    size="xs"
                >
                    {signer.signer_phone_invite || signer.email}
                </MenuButton>
                <MenuList>
                    {signer.sign_link ? (
                        signer.role == 'Owner' && props.appMode != 'superAdmin' ? (
                            <MenuItem>
                                {signer.status == 'fulfilled' ? 'Signed' : 'Pending'}
                            </MenuItem>
                        ) : (
                            <MenuItem>
                                <Link href={signer.sign_link}>Start signing</Link>
                            </MenuItem>
                        )
                    ) : (
                        <MenuItem>{signer.status == 'fulfilled' ? 'Signed' : 'Pending'}</MenuItem>
                    )}
                </MenuList>
            </Menu>
        </Box>
    );
};

const FileUploader = (props: DocumentsBoxProps) => {
    const label = 'Upload';
    const buttonStyles = useMultiStyleConfig('Button', { variant: 'outline', size: 'md' });
    const inputId = useId();
    const sdk = useApiSdk();
    const invalidate = useInvalidate();
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChange = async (e: any) => {
        setIsUploading(true);

        const result = await sdk.UploadAttachment({
            orderId: props.order.id,
            file: e.target.files[0],
        });

        if (result.uploadAttachment && 'id' in result.uploadAttachment) {
            // reload order to update documents view
            invalidate({
                resource: 'orders',
                invalidates: ['detail'],
                id: props.order.id,
            });
        }

        setIsUploading(false);
    };

    return (
        <>
            <Button
                variant="outline"
                leftIcon={<IconUpload />}
                isLoading={isUploading}
                position="relative"
            >
                {label}
                <Box
                    as="label"
                    display="inline-block"
                    htmlFor={inputId}
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    cursor="pointer"
                />
            </Button>
            <Input
                id={inputId}
                onChange={handleFileChange}
                type="file"
                display="none"
                accept="application/pdf"
            />
        </>
    );
};

export const DocumentsBox = (props: DocumentsBoxProps) => {
    const appModeData = useAppModeData();

    return (
        <Card>
            <CardHeader>
                <Heading size="md">Documents</Heading>
            </CardHeader>
            <CardBody>
                <Table colorScheme="gray">
                    <Thead>
                        <Tr>
                            <Th pl={0}>Name</Th>
                            <Th>Signature Status</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {props.order?.rtoContract?.documents.map((doc: any) => (
                            <Tr key={doc.id}>
                                <Td pl={0}>{doc.name}</Td>
                                <Td>
                                    {doc.signers && doc.signers.length > 0 && (
                                        <Stack fontSize="sm">
                                            {doc.signers.map((signer: any) => (
                                                <SignerInfo
                                                    key={signer.id}
                                                    signer={signer}
                                                    appMode={appModeData.mode}
                                                />
                                            ))}
                                        </Stack>
                                    )}
                                </Td>
                                <Td textAlign="right">
                                    <IconButton
                                        as="a"
                                        href={doc.downloadLink}
                                        variant="ghost"
                                        icon={<IconDownload />}
                                        aria-label="Download"
                                        download
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Flex mt={4} justifyContent="space-between">
                    <FileUploader order={props.order} />
                    <Button
                        as="a"
                        href={`${API_BASE_URL}/document/rtopro/${props.order.id}`}
                        download
                        variant="outline"
                        leftIcon={<IconDownload />}
                    >
                        Export
                    </Button>
                </Flex>
            </CardBody>
        </Card>
    );
};
